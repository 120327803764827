var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9c931a23a2baaa08ca6c83b433b4dd7541ce9440"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://62029c457c3340339f876527171966a8@o4504721616601088.ingest.sentry.io/4505390221230080',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.5
  });
} else {
  console.log('Sentry is not running in development mode');
}
