import {
  Button,
  Center,
  Dialog,
  Group,
  Rating,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLog } from '../../LogEventContext';

export enum feedback_types {
  NONE = '',
  CREATE_LIST = 'created_list',
  AI_LIST = 'AI_List',
  QUIZ = 'quiz',
}

export default function FeedbackDialog({
  opened,
  onClose,
  f_ref,
}: {
  opened: boolean;
  onClose: () => void;
  f_ref: feedback_types;
}) {
  const [rating, setRating] = useState(0);
  const [feedbackText, setFeedbackText] = useState('');
  const { LogEvent } = useLog();

  const getDialogText = () => {
    switch (f_ref) {
      case feedback_types.CREATE_LIST:
        return 'How was your experience creating this tier list?';
      case feedback_types.AI_LIST:
        return 'How was your experiencing creating this list with AI?';
      case feedback_types.QUIZ:
        return 'How is your experience with this quiz?';
      default:
        return 'Your review helps us improve!';
    }
  };

  useEffect(() => {
    console.log('-');

    return () => {
      setRating(0);
      setFeedbackText('');
    };
  }, []);

  useEffect(() => {
    if (opened && f_ref && !rating) {
      LogEvent('user_feedback_impression', undefined, { type: f_ref });
    }
  }, [opened]);
  const user_rated = (new_rating: number) => {
    setRating(new_rating);
    LogEvent('user_rating', new_rating, { ref_type: f_ref });
  };

  const user_feedback_submit = () => {
    LogEvent('user_feedback', feedbackText, { ref_type: f_ref, rating });
    onClose();
    setFeedbackText('');
    setRating(0);
  };
  return (
    <>
      <Dialog
        opened={opened}
        withCloseButton
        withBorder
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.violet[9] : theme.colors.violet[2],
          // color: theme.colorScheme === 'dark' ? theme.colors.yellow[4] : theme.colors.blue[6],
        })}
        onClose={() => {
          onClose();
          setRating(0);
          setFeedbackText('');
        }}
        shadow="xl"
        p={15}
        radius="sm"
        size={400}
        h="100%"
      >
        <Stack sx={{ gap: 0, height: 80 }}>
          <Text size={isMobile ? 'md' : 'xl'} mb="xs" weight={500} lineClamp={1}>
            {rating
              ? `${rating === 5 ? 'What did we do well?' : 'How can we improve?'}`
              : getDialogText()}
          </Text>
          <Group style={{ gap: 0, justifyContent: 'center' }}>
            {!rating && (
              <Rating
                value={rating}
                fractions={20}
                onChange={user_rated}
                color="violet"
                size="xl"
              />
            )}
            {rating && (
              <>
                <Textarea
                  value={feedbackText}
                  onChange={(e) => {
                    setFeedbackText(e.target.value);
                  }}
                  minRows={3}
                  w="100%"
                  h="100%"
                />
                <Button mt="sm" disabled={!feedbackText} onClick={user_feedback_submit}>
                  Submit
                </Button>
              </>
            )}
          </Group>
        </Stack>
      </Dialog>
    </>
  );
}
