import { useMemo } from 'react';
import { applySnapshot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import localForage from 'localforage';
import { persist } from 'mst-persist';
import { Statsig } from 'statsig-react';
import { SupabaseClient } from '@supabase/supabase-js';
import { Database } from '../types/supabase';
import { VALID_SUBSCRIPTION_STATES } from '../src/Constants';
import { TierData, UploadImageData } from '../components/GradientPicker/GradientPicker';
import { defaultTiers } from '../src/Utils';
import { useLog } from '../LogEventContext';
import { feedback_types } from '../components/FeedbackDialog/FeedbackDialog';

let store: IStore | undefined;

const CreateStore = types
  .model('CreateStore')
  .props({
    name: '',
    description: '',
    category: '',
    visiblity: 'public',
    orientation: 'square',
    captions: true,
    fileNameCaptions: false,
    images: types.frozen<UploadImageData[]>([]),
    thumbnail: types.frozen<UploadImageData | null>(null),
    activeStep: 0,
    premiumImages: 0,
    password: types.frozen<string | null>(null),
    tiersData: types.frozen<TierData[]>(defaultTiers),
    editId: types.frozen<number | null>(null),
  })
  .volatile<{
    logEvent?: (name: string, value?: string | number, metadata?: any) => void;
  }>((self) => ({
    logEvent: undefined,
  }))
  .actions((self) => ({
    setPassword(text: string | null) {
      self.password = text;
    },
    setCreateLogEvent(logEvent: (name: string, value?: string | number, metadata?: any) => void) {
      self.logEvent = logEvent;
    },
    setCaptions(captions: boolean) {
      self.captions = captions;
    },
    setFileNameCaptions(captions: boolean) {
      self.fileNameCaptions = captions;
    },
    setPremiumImages(imageNumber: number) {
      self.premiumImages = imageNumber;
    },
    setOrientation(orientation: string) {
      self.orientation = orientation;
    },
    setVisibility(value: string) {
      self.visiblity = value;
    },
    setName(name: string) {
      self.name = name;
    },
    setDescription(description: string) {
      self.description = description;
    },
    setEditId(editId: number | null) {
      self.editId = editId;
    },
    setCategory(category: string) {
      self.category = category;
    },
    setImages(images: UploadImageData[]) {
      if (self.images.length === 0 && images.length > 0 && !self.editId) {
        if (self.logEvent) {
          self.logEvent('tier_image_upload', undefined, {
            isEdit: self.editId ? 'true' : 'false',
          });
        }
      }
      self.images = images;
    },
    addImages(images: UploadImageData[]) {
      if (self.images.length === 0 && images.length > 0 && !self.editId) {
        if (self.logEvent) {
          self.logEvent('tier_image_upload', undefined, {
            isEdit: self.editId ? 'true' : 'false',
          });
        }
      }
      self.images = [...self.images, ...images];
    },
    reloadImages() {
      self.images = [...self.images];
    },
    removeImage(id: string) {
      self.images = self.images.filter((image) => image.id !== id);
    },
    setThumbnail(thumbnail: UploadImageData | null) {
      self.thumbnail = thumbnail;
    },
    setActiveStep(step: number) {
      self.activeStep = step;
    },
    setTierItemProp(idx: number, prop: string, value: any) {
      const tierData = self.tiersData[idx] as any;
      tierData[prop] = value;

      const newData = [...self.tiersData];
      newData[idx] = tierData;
      self.tiersData = newData;
    },
    appendTierData(item: TierData) {
      self.tiersData = [...self.tiersData, item];
    },
    removeTierDataIdx(idx: number) {
      self.tiersData = [...self.tiersData.slice(0, idx).concat(self.tiersData.slice(idx + 1))];
    },
    setTiersData(tiersData: TierData[]) {
      self.tiersData = tiersData;
    },
    resetCreate() {
      this.setActiveStep(0);
      this.setImages([]);
      this.setCategory('');
      this.setName('');
      this.setDescription('');
      this.setThumbnail(null);
      this.setVisibility('public');
      this.setEditId(null);
      this.setPremiumImages(0);
      this.setPassword(null);
      self.tiersData = defaultTiers;
    },
  }));

export type ICreateStore = Instance<typeof CreateStore>;
export type ICreateStoreSnapshotIn = SnapshotIn<typeof CreateStore>;
export type ICreateStoreSnapshotOut = SnapshotOut<typeof CreateStore>;

const Store = types
  .model('RootStore')
  .props({
    tierSearch: '',
    createStore: types.optional(CreateStore, {}),
    paywallOpen: false,
    feedbackOpen: false,
    feedbackRef: types.frozen<feedback_types>(feedback_types.NONE),
    feedbackList: types.frozen<{ ref: string; sent: number }[]>([]),
    carouselResultList: types.frozen<{ tier_item: string; selection_id?: string }[]>([]),
    carouselResultIndex: 0,
    username: ''
  })
  .volatile<{
    loginOpen: boolean;
    hasPremium: boolean;
    premiumLoaded: boolean;
    premiumPastDue: boolean;
    loaded: boolean;
    logEvent?: (name: string, value?: string | number, metadata?: any) => void;
  }>((self) => ({
    loginOpen: false,
    hasPremium: false,
    premiumLoaded: false,
    premiumPastDue: false,
    loaded: false,
  }))
  .actions((self) => ({
    setUsername(username: string) {
      self.username = username;
    },
    setCarouselResultList(resultList: { tier_item: string; selection_id?: string }[]) {
      self.carouselResultList = resultList;
    },
    setCarouselResultIndex(resultIndex: number) {
      self.carouselResultIndex = resultIndex;
    },
    setPaywallOpen(value: boolean, ref = '') {
      if (self.logEvent) {
        self.logEvent('paywall_view', ref);
      }
      self.paywallOpen = value;
    },
    setFeedbackList(feedbackList: any) {
      self.feedbackList = feedbackList;
    },
    setFeedbackOpen(value: boolean, ref = feedback_types.NONE) {
      if (self.feedbackOpen) {
        if (!value) {
          self.feedbackOpen = value;
        }
        return;
      }
      const updatedList = [...self.feedbackList]; // Clone the feedbackList to avoid mutations
      const feedbackItemIndex = updatedList.findIndex((item) => item.ref === ref);

      // Feedback item exists in the list
      if (feedbackItemIndex !== -1) {
        const timeDifference = Date.now() - updatedList[feedbackItemIndex].sent;

        if (timeDifference > 14 * 24 * 60 * 60 * 1000) {
          self.feedbackRef = ref;
          self.feedbackOpen = value;

          // Update the sent timestamp of the feedback item
          updatedList[feedbackItemIndex].sent = Date.now();
        } else {
          return;
        }
      }
      // Feedback item does not exist in the list, add it
      else {
        self.feedbackRef = ref;
        self.feedbackOpen = value;
        updatedList.push({ ref, sent: Date.now() });
      }

      // Use setFeedbackList to update the feedbackList
      this.setFeedbackList(updatedList);
      console.log(self.feedbackList);
    },

    setLogEvent(logEvent: (name: string, value?: string | number, metadata?: any) => void) {
      self.logEvent = logEvent;
    },
    setLoginOpen(value: boolean) {
      self.loginOpen = value;
    },
    setHasPremium(value: boolean) {
      self.hasPremium = value;
      self.premiumLoaded = true;
    },
    setPremiumPastDue(value: boolean) {
      self.premiumPastDue = value;
    },
    setTierSearch(value: string) {
      self.tierSearch = value;
    },
    async loadHasPremium(supabase: SupabaseClient<Database>, uid: string) {
      const { data, error } = await supabase
        .from('subscriptions')
        .select('*')
        .eq('user_id', uid)
        .single();
      if (data && data.status) {
        this.setHasPremium(VALID_SUBSCRIPTION_STATES.includes(data.status));
        if (data.status === 'past_due') {
          this.setPremiumPastDue(true);
        }
      } else {
        this.setHasPremium(false);
      }

      const { data: profileData } = await supabase.from('profiles').select('*').eq('id', uid).single();

      if (profileData) {
        this.setUsername(profileData.username);
      }
    },
    setLoaded(loaded: boolean) {
      self.loaded = loaded;
    },
  }));

export type IStore = Instance<typeof Store>;
export type IStoreSnapshotIn = SnapshotIn<typeof Store>;
export type IStoreSnapshotOut = SnapshotOut<typeof Store>;

export function initializeStore(snapshot = null) {
  let _store;
  if (store) {
    _store = store;
  } else {
    _store = Store.create();
    if (typeof window !== 'undefined') {
      persist('RootStore', _store, {
        storage: localForage, // or AsyncStorage in react-native.
        // default: localStorage
        jsonify: false, // if you use AsyncStorage, this shoud be true
        // default: true
      })
        .then(() => {
          store?.setLoaded(true);
        })
        .catch(() => {
          store?.setLoaded(true);
        });
    }
  }

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  // get hydrated here, check `pages/ssg.tsx` and `pages/ssr.tsx` for more details
  if (snapshot) {
    applySnapshot(_store, snapshot);
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return store;
}

export function useStore(initialState: any) {
  const memoStore = useMemo(() => initializeStore(initialState), [initialState]);
  return memoStore;
}
