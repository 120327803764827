import { useEffect, useState } from 'react';
import NextApp, { AppProps, AppContext } from 'next/app';
import { getCookie, setCookie } from 'cookies-next';
import Head from 'next/head';
import Smartlook from 'smartlook-client';
import {
  MantineProvider,
  ColorScheme,
  ColorSchemeProvider,
  Global,
  AppShell,
  useMantineTheme,
} from '@mantine/core';
import Cookies from 'js-cookie';
import { Notifications } from '@mantine/notifications';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider, Session } from '@supabase/auth-helpers-react';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';
import '../components/Create/pintura.css';
import localFont from 'next/font/local';
import { Analytics } from '@vercel/analytics/react';
import Script from 'next/script';
import { ModalsProvider } from '@mantine/modals';
import TagManager from 'react-gtm-module';
import { v4 as uuid } from 'uuid';
import { NavigationProgress } from '@mantine/nprogress';
import { isMobile } from 'react-device-detect';
import * as LomoTime from '../LomoTimeTracker';
import { Database } from '../types/supabase';
// import satoshi from '../fonts/Satoshi-Variable.woff2';
import HeaderMegaMenu from '../components/Header/Header';
import { useStore } from '../stores/stores';
import FooterLinks from '../components/Footer/Footer';
import { RouterTransition } from '../components/RouterTransition';
import { IS_DEV, UID_COOKIE } from '../src/Constants';
import { LogProvider, useLog } from '../LogEventContext';
import { StatsigWrapper } from '../experiments/StatsigWrapper';

export const SatoshiVariable = localFont({ src: './Satoshi-Variable.woff2' });

export default function App(props: AppProps & { colorScheme: ColorScheme }) {
  const { Component, pageProps } = props;
  const [colorScheme, setColorScheme] = useState<ColorScheme>(props.colorScheme);

  const toggleColorScheme = (value?: ColorScheme) => {
    const nextColorScheme = value || (colorScheme === 'dark' ? 'light' : 'dark');
    setColorScheme(nextColorScheme);
    setCookie('mantine-color-scheme', nextColorScheme, { maxAge: 60 * 60 * 24 * 30 });
  };
  const [supabase] = useState(() => createBrowserSupabaseClient<Database>());
  const router = useRouter();

  const pageKey = router.asPath;

  const theme = useMantineTheme();
  let cookieID = Cookies.get(UID_COOKIE);
  if (!cookieID) {
    cookieID = uuid();
    Cookies.set(UID_COOKIE, cookieID, { expires: 365 });
  }

  const [userID, setUserID] = useState<string | undefined>(undefined);
  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      setUserID(session?.user.id);
      if (!LomoTime.getAuthHeader() || session?.access_token !== LomoTime.getAuthHeader()) {
        LomoTime.authStateChanged(session?.access_token, window.location.pathname);
      }
    });
  });

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-P9QWVJF' });

    if (!IS_DEV) {
      Smartlook.init('9c4783c93c5af02a3f88ed5cab7b752b5cca208c', {
        region: 'eu',
      });
      const success = Smartlook.record({ forms: true, numbers: true, emails: true, ips: true });
      console.log(success);
    }
  }, []);

  const isTierPage = () => {
    if (
      router.pathname === '/[[...slug]]' &&
      router.query.slug &&
      typeof router.query.slug !== 'string'
    ) {
      return true;
    }

    if (router.pathname === '/drake-kendrick') {
      return true;
    }
    return false;
  };

  return (
    <>
      <Script id="gtag-script">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
        `}
      </Script>

      {/* <Script id="freshchat" src="//fw-cdn.com/9220282/3393662.js" /> */}
      <Head>
        <title>Lomo | Tier List Maker</title>
        <meta name="viewport" content="maximum-scale=1, initial-scale=1, width=device-width" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content={colorScheme === 'dark' ? '#5F3DC4' : '#ffffff'} />
      </Head>
      <SessionContextProvider supabaseClient={supabase} initialSession={pageProps.initialSession}>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider
            theme={{
              colorScheme,
              fontFamily: SatoshiVariable.style.fontFamily,
              fontFamilyMonospace: SatoshiVariable.style.fontFamily,
              headings: { fontFamily: SatoshiVariable.style.fontFamily },
              primaryColor: 'violet',
              breakpoints: {
                xxs: '30em',
              },
            }}
            withGlobalStyles
            withNormalizeCSS
          >
            <RouterTransition />
            <ModalsProvider>
              <Global
                styles={[
                  {
                    '@font-face': {
                      fontFamily: SatoshiVariable.style.fontFamily,
                      fontWeight: [300, 900],
                      fontStyle: 'normal',
                      fontDisplay: 'swap',
                    },
                  },
                  {
                    '::selection': {
                      background: '#FF4ECD;',
                    },
                  },
                  {
                    ':root': {
                      '--default-font-family': SatoshiVariable.style.fontFamily,
                    },
                  },
                  {
                    '#fc_frame': {
                      colorScheme: 'light',
                    },
                  },
                ]}
              />
              <Notifications position="top-right" limit={7} />
              <LogProvider cookieID={cookieID} supabaseClient={supabase}>
                <StatsigWrapper userID={userID} cookieID={cookieID}>
                  <AppShell
                    styles={{
                      main: { padding: 0 },
                    }}
                    navbarOffsetBreakpoint="sm"
                    asideOffsetBreakpoint="sm"
                    fixed={false}
                    header={<HeaderMegaMenu />}
                  >
                    <div
                      style={{
                        position: !isTierPage() ? 'relative' : 'initial',
                        zIndex: 1,
                        backgroundColor: colorScheme === 'dark' ? theme.colors.dark[7] : '#ffffff',
                        minHeight: 400,
                      }}
                    >
                      <AnimatePresence initial={false} mode="wait">
                        <Component key={pageKey} {...pageProps} />
                        <Analytics />
                      </AnimatePresence>
                    </div>
                    {!isTierPage() && <FooterLinks />}
                  </AppShell>
                </StatsigWrapper>
              </LogProvider>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </SessionContextProvider>
    </>
  );
}

App.getInitialProps = async (appContext: AppContext) => {
  const appProps = await NextApp.getInitialProps(appContext);
  return {
    ...appProps,
    colorScheme: getCookie('mantine-color-scheme', appContext.ctx) || 'dark',
  };
};
