import { MantineNumberSize } from '@mantine/core';
import {
  FunctionsFetchError,
  FunctionsRelayError,
  FunctionsHttpError,
  createClient,
} from '@supabase/supabase-js';
import { v4 as uuid } from 'uuid';
import { FunctionInvokeOptions, FunctionsResponse } from '@supabase/functions-js';
import Cookies from 'js-cookie';
import { Cloudinary } from '@cloudinary/url-gen';
import { UID_COOKIE } from './Constants';
import { Database } from '../types/supabase';
import {
  TierData,
  TierPresets,
  TIER_COLOR_SWATCH,
} from '../components/GradientPicker/GradientPicker';

import { TierItemData } from '../components/TierListPage/TierGrid';
import { SatoshiVariable } from '../pages/_app';

export const cld = new Cloudinary({
  cloud: {
    cloudName: 'dazwytwuw',
  },
});

export const defaultTiers: TierData[] = [
  {
    name: 'S',
    id: uuid(),
    preset: TierPresets.DEFAULT,
    backgroundColor: TIER_COLOR_SWATCH[0],
    textColor: 'white',
  },
  {
    name: 'A',
    id: uuid(),
    preset: TierPresets.DEFAULT,
    backgroundColor: TIER_COLOR_SWATCH[1],
    textColor: 'white',
  },
  {
    name: 'B',
    id: uuid(),
    preset: TierPresets.DEFAULT,
    backgroundColor: TIER_COLOR_SWATCH[2],
    textColor: 'white',
  },
  {
    name: 'C',
    id: uuid(),
    preset: TierPresets.DEFAULT,
    backgroundColor: TIER_COLOR_SWATCH[4],
    textColor: 'white',
  },
  {
    name: 'D',
    id: uuid(),
    preset: TierPresets.DEFAULT,
    backgroundColor: TIER_COLOR_SWATCH[7],
    textColor: 'white',
  },
];

export const getSizeForTierName = (name: string): MantineNumberSize => {
  if (name.length < 4) {
    return 50;
  }
  if (name.length <= 9) {
    return 30;
  }
  if (name.length < 25) {
    return 'xl';
  }
  if (name.length < 30) {
    return 'lg';
  }
  if (name.length < 35) {
    return 'md';
  }
  return 'xs';
};

export const supabaseAnonClient = createClient<Database>(
  process.env.NEXT_PUBLIC_SUPABASE_URL ?? '',
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? ''
);

export const imageToBlob = async (inputURI: string) => {
  const BlobImage = await (await fetch(inputURI)).blob();
  return BlobImage;
};

export const exportAsImage = async (el: HTMLElement) => {
  const htmlToImage = (await import('html-to-image')).toPng;
  const newImage = await htmlToImage(el, {
    includeQueryParams: true,
  });
  return newImage;
};

export async function exportAsBlob(el: HTMLElement): Promise<Blob | null> {
  const htmlToImage = (await import('html-to-image')).toCanvas;
  const canvas = await htmlToImage(el, {
    includeQueryParams: true,
  });
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (tBlob) => {
        const finalBlob = tBlob;
        if (!finalBlob) {
          reject(new Error('No Blob Found'));
        } else {
          resolve(finalBlob);
        }
      },
      'image/webp',
      1.0
    );
  });
}

export const noDragStyles = {
  WebkitUserDrag: 'none',
  KhtmlUserDrag: 'none',
  MozUserDrag: 'none',
  OUserDrag: 'none',
  userDrag: 'none',
};

export function delay(timeout: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
}

export function delayUntil(
  condition: () => boolean,
  maxDelay = Infinity,
  delayStep = 250
): Promise<void> {
  return new Promise((resolve, reject) => {
    let checkTimeout: NodeJS.Timeout | null = null;
    let rejectTimeout: NodeJS.Timeout | null = null;
    if (maxDelay !== Infinity) {
      rejectTimeout = setTimeout(() => {
        if (checkTimeout) {
          clearTimeout(checkTimeout);
        }
        reject();
      }, maxDelay);
    }
    function checkCondition() {
      if (condition()) {
        if (rejectTimeout) {
          clearTimeout(rejectTimeout);
        }
        resolve();
      } else {
        checkTimeout = setTimeout(checkCondition, delayStep);
      }
    }
    checkCondition();
  });
}

export async function invoke<T = any>(
  functionName: string,
  url: string,
  token: string,
  options: FunctionInvokeOptions = {}
): Promise<FunctionsResponse<T>> {
  try {
    const { headers, method, body: functionArgs } = options;

    const _headers: Record<string, string> = {
      Authorization: `Bearer ${token}`,
    };
    let body: any;
    if (
      functionArgs &&
      ((headers && !Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) || !headers)
    ) {
      if (
        (typeof Blob !== 'undefined' && functionArgs instanceof Blob) ||
        functionArgs instanceof ArrayBuffer
      ) {
        // will work for File as File inherits Blob
        // also works for ArrayBuffer as it is the same underlying structure as a Blob
        _headers['Content-Type'] = 'application/octet-stream';
        body = functionArgs;
      } else if (typeof functionArgs === 'string') {
        // plain string
        _headers['Content-Type'] = 'text/plain';
        body = functionArgs;
      } else if (typeof FormData !== 'undefined' && functionArgs instanceof FormData) {
        // don't set content-type headers
        // Request will automatically add the right boundary value
        body = functionArgs;
      } else {
        // default, assume this is JSON
        _headers['Content-Type'] = 'application/json';
        body = JSON.stringify(functionArgs);
      }
    }

    const response = await fetch(`${url}/${functionName}`, {
      method: method || 'POST',
      // headers priority is (high to low):
      // 1. invoke-level headers
      // 2. client-level headers
      // 3. default Content-Type header
      headers: { ..._headers, ...headers },
      body,
    }).catch((fetchError) => {
      throw new FunctionsFetchError(fetchError);
    });

    const isRelayError = response.headers.get('x-relay-error');
    if (isRelayError && isRelayError === 'true') {
      throw new FunctionsRelayError(response);
    }

    if (!response.ok) {
      throw new FunctionsHttpError(response);
    }

    const responseType = (response.headers.get('Content-Type') ?? 'text/plain')
      .split(';')[0]
      .trim();
    let data: any;
    if (responseType === 'application/json') {
      data = await response.json();
    } else if (responseType === 'application/octet-stream') {
      data = await response.blob();
    } else if (responseType === 'multipart/form-data') {
      data = await response.formData();
    } else {
      // default to text
      data = await response.text();
    }

    return { data, error: null };
  } catch (error) {
    return { data: null, error };
  }
}

export const fillText = (
  ctx: CanvasRenderingContext2D,
  text: string,
  opts: {
    font?: string;
    stroke?: boolean;
    verbose?: boolean;
    rect: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    lineHeight?: number;
    minFontSize?: number;
    maxFontSize?: number;
    logFunction?: (text: string) => void;
  }
) => {
  if (!opts.font) opts.font = 'sans-serif';
  if (typeof opts.stroke === 'undefined') opts.stroke = false;
  if (typeof opts.verbose === 'undefined') opts.verbose = false;
  if (!opts.rect)
    opts.rect = {
      x: 0,
      y: 0,
      width: ctx.canvas.width,
      height: ctx.canvas.height,
    };
  if (!opts.lineHeight) opts.lineHeight = 1.1;
  if (!opts.minFontSize) opts.minFontSize = 14;
  if (!opts.maxFontSize) opts.maxFontSize = 70;
  // Default log function is console.log - Note: if verbose il false, nothing will be logged anyway
  if (!opts.logFunction)
    opts.logFunction = function (message) {
      console.log(message);
    };

  const words = text.split(' ');
  if (opts.verbose) opts.logFunction(`Text contains ${words.length} words`);
  let lines = [];

  // Finds max font size  which can be used to print whole text in opts.rec
  let fontSize;
  for (fontSize = opts.minFontSize; fontSize <= opts.maxFontSize; fontSize += 1) {
    // Line height
    const lineHeight = fontSize * opts.lineHeight;

    // Set font for testing with measureText()
    ctx.font = ` ${fontSize}px ${opts.font}`;

    // Start
    const { x } = opts.rect;
    let y = opts.rect.y + fontSize; // It's the bottom line of the letters
    lines = [];
    let line = `${words[0]} ` ?? '';

    // Cycles on words
    let stop = false;

    if (ctx.measureText(line).width > opts.rect.width - 10) {
      stop = true;
    }

    for (let i = 1; i < words.length; i += 1) {
      const word = words[i];
      // Add next word to line
      const linePlus = `${i === 1 ? ' ' : ''} ${line + word} `;
      // If added word exceeds rect width...
      if (ctx.measureText(linePlus).width > opts.rect.width) {
        if (line.split(' ').length === 2) {
          console.log('Should have stopped');
          stop = true;
        }
        // ..."prints" (save) the line without last word
        lines.push({ text: line, x, y });
        // New line with ctx last word
        line = `${word} `;
        y += lineHeight;
      } else {
        // ...continues appending words
        line = linePlus;
      }
    }

    // "Print" (save) last line
    lines.push({ text: line, x, y });

    // If bottom of rect is reached then breaks "fontSize" cycle

    if (y > opts.rect.y + opts.rect.height || stop) {
      ctx.font = ` ${fontSize - 1}px ${opts.font}`;
      break;
    }
  }

  if (opts.verbose) opts.logFunction(`Font used: ${ctx.font}`);

  // Print lines
  const height = lines[lines.length - 1].y - opts.rect.y;

  const offset = (opts.rect.height - height) / 2;

  for (let i = 0; i < lines.length; i += 1) {
    const line = lines[i];
    // Fill or stroke
    if (opts.stroke) ctx.strokeText(line.text.trim(), line.x, line.y + offset);
    else ctx.fillText(line.text.trim(), line.x, line.y + offset);
  }

  // Returns height
  return height;
};

/**
 * Function to shuffle an array based on a string value (UUID)
 * @param array - The array to be shuffled
 * @param uuid - The UUID string value
 * @returns - The shuffled array
 */
export function shuffleArrayBasedOnUUID<T>(array: T[], shuffle_id: string): T[] {
  // Convert the UUID string into a number
  let seed = 0;
  for (let i = 0; i < shuffle_id.length; i += 1) {
    seed += shuffle_id.charCodeAt(i);
  }

  // Function to generate a pseudo-random number based on the seed
  function seededRandom(): number {
    const x = Math.sin((seed += 1)) * 10000;
    return x - Math.floor(x);
  }

  // Shuffle the array using the Fisher-Yates (aka Durstenfeld, aka Knuth) algorithm
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(seededRandom() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
}

export function getDeviceID() {
  return (
    Cookies.get(UID_COOKIE) ?? localStorage.getItem('STATSIG_LOCAL_STORAGE_STABLE_ID') ?? uuid()
  );
}
