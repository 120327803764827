import {
  Modal,
  useMantineTheme,
  Text,
  Title,
  Button,
  Image,
  Center,
  Stack,
  Tooltip,
  createStyles,
} from '@mantine/core';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';
import { Auth } from '@supabase/auth-ui-react';
import { useEffect, useState } from 'react';
import {
  // Import predefined theme
  ThemeSupa,
} from '@supabase/auth-ui-shared';
import Link from 'next/link';
import { useMediaQuery } from '@mantine/hooks';
import { useStore } from '../../stores/stores';

export default function UpgradeModal({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}) {
  const theme = useMantineTheme();
  const [supabase] = useState(() => createBrowserSupabaseClient());
  const { premiumPastDue } = useStore(null);
  const largeScreen = useMediaQuery('(min-width: 60em)');
  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        fullScreen={!largeScreen}
        overlayProps={{
          opacity: 0.3,
        }}
        centered
        title={
          <Tooltip label="//TODO: make this nicer?" color="dark">
            <Text fw={900} variant="gradient" gradient={{ from: 'violet', to: 'pink' }}>
              Lol get rekt.
            </Text>
          </Tooltip>
        }
      >
        <Center>
          <Stack align="center">
            <Text ta="center">
              {' '}
              Congratualtions you&apos;ve been paywalled! This feature is only available for
              LomoPlus subscribers.
            </Text>
            <Image src="https://media.tenor.com/jg0-zHyA_8oAAAAi/winnie-the-pooh-pooh-bear.gif" />
            {premiumPastDue ? (
              <>
                <Text fw={700} ta="center" color="red">
                  It looks like payment failed for your most recent subscription invoice.
                </Text>
                <Link
                  href="https://billing.stripe.com/p/login/eVa3ePfSB2BX03C8ww"
                  passHref
                  target="_blank"
                >
                  <Button> Update your subscription</Button>
                </Link>
              </>
            ) : (
              <>
                <Text fw={700}>
                  {' '}
                  Not sure if it&apos;s worth it?{' '}
                  <Text
                    component="span"
                    fw={900}
                    variant="gradient"
                    gradient={{ from: 'violet', to: 'pink' }}
                  >
                    Start a free trial now!
                  </Text>
                </Text>
                <Link href="/upgrade" onClick={onClose}>
                  <Button> Upgrade for Free</Button>
                </Link>
                <Link href="/upgrade" target="_blank" onClick={onClose}>
                  <Button variant="subtle"> Learn More</Button>
                </Link>
              </>
            )}
          </Stack>
        </Center>
      </Modal>
    </>
  );
}
