import { Button, DEFAULT_THEME, SimpleGrid, useMantineTheme } from '@mantine/core';

export const TIER_GRADIENTS = [
  DEFAULT_THEME.fn.linearGradient(180, '#0037FC', '#F60063'),
  'linear-gradient(180deg, #ff008c 0%, rgb(211, 9, 225) 100%)',
  'linear-gradient(19deg, #3EECAC 0%, #EE74E1 100%)',
  'linear-gradient(90deg, #FF9A8B 0%, #FF6A88 55%, #FF99AC 100%)',
  'linear-gradient(45deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)',
  'linear-gradient(to right, #e65c00, #f9d423)',
  'linear-gradient(to right, #cc2b5e, #753a88)',
  '',
];

export interface UploadImageData {
  src: File | string;
  name: string;
  id: string;
  updating: boolean;
  pintura?: {
    imageState: any;
    dest: File;
    url: string;
  };
}

export interface TierData {
  name: string;
  id: string;
  imageId?: string;
  backgroundColor: string;
  textColor: string;
  preset: TierPresets;
  gradient?: string;
  image?: UploadImageData;
}

export enum TierPresets {
  DEFAULT = 'default',
  GRADIENT = 'gradient',
  IMAGE = 'image',
}

export const TIER_COLOR_SWATCH = [
  'red',
  'pink',
  'orange',
  'yellow',
  'teal',
  'green',
  'lime',
  'cyan',
  'blue',
  'indigo',
  'violet',
  'grape',
  'dark',
  'gray', 
]
  .map((color) => [DEFAULT_THEME.colors[color][6]])
  .flat();

export default function GradientPicker({
  selected,
  setSelected,
}: {
  selected: string | undefined;
  setSelected: (newVal: string) => void;
}) {
  return (
    <SimpleGrid cols={4}>
      {TIER_GRADIENTS.map((gradient, idx) => (
        <Button
          key={idx}
          sx={{
            border: selected === gradient ? '2px solid red' : 'none',
            aspectRatio: '1 / 1',
            width: 40,
            height: 40,
          }}
          variant="gradient"
          bg={gradient}
          onClick={() => {
            setSelected(gradient);
          }}
        />
      ))}
    </SimpleGrid>
  );
}
