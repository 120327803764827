export const LOMO_DESCRIPTIONS = [
  'most delightful',
  'cleanest',
  'best looking',
  'most perspicacious',
  'fastest',
  'most revolutionary',
  'sexiest',
  'most controversial',
];
export const EDGE_FUNCTIONS_URL = 'https://ylzbnnsfouiblshzmkrd.functions.supabase.co';
// export const EDGE_FUNCTIONS_URL = 'http://localhost:54321/functions/v1';
export const MIN_NAME_LENGTH = 3;
export const MIN_PASSWORD_LENGTH = 3;
export const MAX_NAME_LENGTH = 64;
export const MIN_DESCRIPTION_LENGTH = 10;
export const MAX_DESCRIPTION_LENGTH = 200;
export const MAX_PASSWORD_LENGTH = 32;
export const MAX_CONCURRENT_LOAD = 5;
export const MIN_IMAGES_LENGTH = 3;
export const UID_COOKIE = 'uid';
export const LOMO_JOKES = [
  'Why don’t scientists trust atoms?',
  'Because they make up everything!',
  'Why couldn’t the leopard play hide and seek?',
  'Because he was always spotted!',
  'What do you call cheese that isn’t yours?',
  'Nacho cheese!',
  'Why did the scarecrow win an award?',
  'Because he was outstanding in his field!',
  'Why don’t some animals play cards?',
  'Because they’re afraid of cheetahs!',
  'What is a computer’s favorite snack?',
  'Computer chips!',
  'Why was the math book sad?',
  'Because it had too many problems!',
  'Why couldn’t the bicycle stand up by itself?',
  'Because it was two-tired!',
  'Why did the cookie go to the hospital?',
  'Because it felt crumby!',
  'What’s orange and sounds like a parrot?',
  'A carrot!',
  'What did the zero say to the eight?',
  'Nice belt!',
  "Why don't we ever tell secrets on a farm?",
  'Because the potatoes have eyes, the corn has ears, and the beans stalk!',
  'What do you call a snowman with a six-pack?',
  'An abdominal snowman!',
  'What did one ocean say to the other ocean?',
  'Nothing, they just waved!',
  'What did the grape do when it got stepped on?',
  'Nothing, it just let out a little wine!',
  'Why did the golfer bring two pairs of pants?',
  'In case he got a hole in one!',
  "Why can't you give Elsa a balloon?",
  'Because she will let it go!',
  'Why did the tomato turn red?',
  'Because it saw the salad dressing!',
  'Why did the teddy bear say no to dessert?',
  'Because he was already stuffed!',
  'Why did the math problem look so sad?',
  'Because it had too many X’s!',
  'Why don’t oysters donate to charity?',
  'Because they are shellfish!',
  'Why don’t skeletons fight each other?',
  'Because they don’t have the guts!',
  'What’s a pirate’s favorite letter?',
  'You’d think it’d be R, but his first love be the C!',
  'Why was the computer cold at the office?',
  'It left its Windows open!',
  'Why don’t scientists trust atoms?',
  'Because they make up everything!',
  'What’s a computer’s favorite beat?',
  'An algorithm!',
];
export const TIER_IMAGES_TRANSFORM_CDN =
  'https://api.lomolist.com/storage/v1/render/image/public/uploads';
export const TIER_IMAGES_CDN = 'https://api.lomolist.com/storage/v1/object/public/uploads';
// export const TIER_IMAGES_CDN = 'http://localhost:54321/storage/v1/object/public/uploads';
export const FALLBACK_IMAGE = 'https://api.lomolist.com/storage/v1/object/public/uploads';
export const TIER_IMAGES_TRANSFORM = '?width=250&height=250';
export const WEBSITE_DOMAIN = 'https://lomolist.com';
export const VALID_SUBSCRIPTION_STATES = ['active', 'trialing'];
export const IS_DEV = process.env.NODE_ENV === 'development';
