import {
  createStyles,
  Header,
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Divider,
  Center,
  Box,
  Burger,
  Drawer,
  Collapse,
  ScrollArea,
  rem,
  Autocomplete,
  Avatar,
  Image,
  ActionIcon,
  Menu,
  SelectItemProps,
  Tooltip,
  Modal,
  MediaQuery,
  TextInput,
  Stack,
  useMantineColorScheme,
} from '@mantine/core';
import { useDebouncedValue, useDisclosure, useScrollLock } from '@mantine/hooks';
import Smartlook from "smartlook-client"
import {
  IconNotification,
  IconCode,
  IconBook,
  IconChartPie3,
  IconFingerprint,
  IconCoin,
  IconChevronDown,
  IconSearch,
  IconHeart,
  IconLogout,
  IconMessage,
  IconPlayerPause,
  IconSettings,
  IconStar,
  IconSwitchHorizontal,
  IconTrash,
} from '@tabler/icons-react';
import confetti from 'canvas-confetti';
import { useRouter } from 'next/router';
import { useSession, useSessionContext, useSupabaseClient } from '@supabase/auth-helpers-react';
import { forwardRef, useEffect, useState } from 'react';
import { IconConfetti, IconListNumbers, IconMoonStars, IconSun } from '@tabler/icons';
import Link from 'next/link';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
// import LoginModal from '../LoginModal/LoginModal';
import * as Sentry from '@sentry/nextjs';
import { Statsig, useExperiment } from 'statsig-react';
import Cookies from 'js-cookie';
import { ColorSchemeToggle } from '../ColorSchemeToggle/ColorSchemeToggle';
import { Database } from '../../types/supabase';
import { TIER_IMAGES_CDN, UID_COOKIE } from '../../src/Constants';
import { useStore } from '../../stores/stores';
import UpgradeModal from '../UpgradeModal/UpgradeModal';
import FeedbackDialog from '../FeedbackDialog/FeedbackDialog';
import { useLog } from '../../LogEventContext';
import StripeUpgradeModal from '../UpgradeModal/StripeUpgradeModal';
import { getDeviceID } from '../../src/Utils';

const md5 = require('md5');

const DynamicLoginModal = dynamic(() => import('../LoginModal/LoginModal'), {});
const DynamicSearchModal = dynamic(() => import('../SearchModal/SearchModal'), {});

const useStyles = createStyles((theme) => ({
  modalInner: {
    paddingTop: rem(80),
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan('sm')]: {
      height: rem(42),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },
  links: {
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },

  search: {
    minWidth: 200,
    [theme.fn.smallerThan('sm')]: {
      //   display: 'none',
    },
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
  user: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    transition: 'background-color 100ms ease',

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },

    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
  },

  userActive: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
  },
}));

const mockdata = [
  {
    icon: IconCode,
    title: 'Open source',
    description: 'This Pokémon’s cry is very loud and distracting',
  },
  {
    icon: IconCoin,
    title: 'Free for everyone',
    description: 'The fluid of Smeargle’s tail secretions changes',
  },
  {
    icon: IconBook,
    title: 'Documentation',
    description: 'Yanma is capable of seeing 360 degrees without',
  },
  {
    icon: IconFingerprint,
    title: 'Security',
    description: 'The shell’s rounded shape and the grooves on its.',
  },
  {
    icon: IconChartPie3,
    title: 'Analytics',
    description: 'This Pokémon uses its flying ability to quickly chase',
  },
  {
    icon: IconNotification,
    title: 'Notifications',
    description: 'Combusken battles with the intensely hot flames it spews',
  },
];

const HeaderMegaMenu = observer(() => {
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  //const [loginOpened, { open: openLoginModal, close: closeLoginModal }] = useDisclosure(false);
  const [isSignup, signupHandlers] = useDisclosure(false);
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const supabase = useSupabaseClient<Database>();
  const { session, isLoading } = useSessionContext();

  const {
    tierSearch,
    setTierSearch,
    hasPremium,
    loadHasPremium,
    setHasPremium,
    premiumLoaded,
    paywallOpen,
    loginOpen,
    setPaywallOpen,
    setLoginOpen,
    premiumPastDue,
    feedbackOpen,
    feedbackRef,
    setFeedbackOpen,
    username
  } = useStore(null);
  const [debouncedSearch] = useDebouncedValue(tierSearch, 200);
  const { LogEvent } = useLog();
  const [autoCompleteData, setAutoCompleteData] = useState<
    { id: string; value: string; description: string; thumbnail: string; slug: string }[]
  >([]);

  const use_stripe_paywall = useExperiment('new_paywall').config.get('stripe_paywall', true);
  const { toggleColorScheme } = useMantineColorScheme();
  interface ItemProps extends SelectItemProps {
    id: string;
    value: string;
    slug: string;
    description: string;
    thumbnail: string;
  }

  useEffect(() => {
    console.log(username)
    try {
        if (Smartlook.initialized()) {
            if (session?.user.id) {
                Smartlook.identify(session.user.id, {
                    name: username,
                    email: session.user?.email ?? "",
                    cookieID: getDeviceID(),
                })
            } else {
                console.log("identify here");
                Smartlook.identify(Smartlook.visitorId ?? getDeviceID(), {
                    cookieID: getDeviceID(),
                })
            }
        }
    } catch (error) {
        Sentry.captureException(error);
    }
    if (session?.user.id) {
      setLoginOpen(false);
      console.log('load premium');
      loadHasPremium(supabase, session?.user.id);
        Sentry.setUser({
          id: session.user.id,
          username,
          email: session.user.email,
        });
      (window as any).gtag('set', 'user_data', {
        email: session.user.email,
      });
      (window as any).gtag('event', 'login');
      LogEvent('login');
    } else if (!isLoading) {
      setHasPremium(false);
    }
  }, [
    username,
    loadHasPremium,
    isLoading,
    session?.user.id,
    session?.user.email,
    setHasPremium,
    setLoginOpen,
    supabase,
  ]);

  useEffect(() => {
    async function loadAutoCompleteData() {
      const { data, error } = await supabase
        .from('tier_lists')
        .select()
        .textSearch('fts', `'${debouncedSearch}'`)
        .eq('visibility', 'public')
        .limit(5);

      if (data && data.length) {
        setAutoCompleteData(
          data.map((res) => ({
            id: String(res.id),
            slug: res.slug,
            value: res.name,
            description: res.description ?? '',
            thumbnail: res.thumbnail ?? '',
          }))
        );
      }
    }
    if (debouncedSearch) {
      loadAutoCompleteData();
    }
  }, [debouncedSearch]);
  const { classes, theme, cx } = useStyles();
  const router = useRouter();
  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group noWrap align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon size={rem(22)} color={theme.fn.primaryColor()} />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" color="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  //   useScrollLock(loginOpen);

  const [searchOpen, setSearchOpen] = useState(false);
  return (
    <Box pb={60}>
      <DynamicLoginModal
        isSignUp={isSignup}
        opened={loginOpen}
        onClose={() => {
          setLoginOpen(false);
        }}
      />
      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <Modal
          opened={searchOpen}
          onClose={() => {
            setSearchOpen(false);
          }}
          classNames={{ inner: classes.modalInner }}
          overlayProps={{
            opacity: 0.9,
          }}
          centered
          title={
            <Text fw={900} size="xl" color="violet">
              Seach for a Template
            </Text>
          }
          transitionProps={{ transition: 'slide-up', duration: 500 }}
          fullScreen
        >
          <Autocomplete
            value={tierSearch}
            onClick={(e) => {}}
            itemComponent={forwardRef<HTMLDivElement, ItemProps>(
              ({ id, value, description, thumbnail, slug, ...others }, ref) => (
                <div ref={ref} {...others}>
                  <Group
                    noWrap
                    onMouseDown={() => {
                      setSearchOpen(false);
                      router.push(`/${id}/${slug}`);
                    }}
                  >
                    <Image
                      src={`${TIER_IMAGES_CDN}/${thumbnail}`}
                      width={60}
                      height={60}
                      withPlaceholder
                    />
                    <div>
                      <Text size="sm" fw="bold" lineClamp={1}>
                        {value}
                      </Text>
                      <Text size="xs" color="dimmed" lineClamp={1}>
                        {description}
                      </Text>
                    </div>
                  </Group>
                </div>
              )
            )}
            onChange={setTierSearch}
            sx={{ width: '100%' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setSearchOpen(false);
                router.push(`/search?q=${encodeURIComponent(tierSearch)}`);
              }
            }}
            className={classes.search}
            placeholder="Search for Tier Lists"
            icon={<IconSearch size={20} stroke={1.5} />}
            data={autoCompleteData}
            size="lg"
          />
        </Modal>
      </MediaQuery>
      {!use_stripe_paywall ? (
        <UpgradeModal
          opened={paywallOpen}
          onClose={() => {
            setPaywallOpen(false);
          }}
        />
      ) : (
        <StripeUpgradeModal
          opened={paywallOpen}
          onClose={() => {
            setPaywallOpen(false);
          }}
        />
      )}
      <FeedbackDialog
        opened={feedbackOpen}
        f_ref={feedbackRef}
        onClose={() => setFeedbackOpen(false)}
      />
      <Header fixed height={60} px="md">
        <Group sx={{ height: '100%', justifyContent: 'space-between' }} align="center">
          <Burger opened={drawerOpened} onClick={toggleDrawer} className={classes.hiddenDesktop} />
          <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            <Group>
              <Link
                href="/"
                passHref
                onClick={(event) => {
                  confetti({
                    particleCount: 100,
                    startVelocity: 10,
                    spread: 180,
                    angle: 300,
                    origin: {
                      x: event.pageX / window.innerWidth,
                      y: event.pageY / window.innerHeight,
                    },
                  });
                  // router.push('/');
                }}
                style={{
                  textDecorationLine: 'none',
                  userSelect: 'none',
                  cursor: 'pointer',
                }}
              >
                <Text
                  inherit
                  variant="gradient"
                  gradient={{ from: '#0037FC', to: '#F60063', deg: 135 }}
                  sx={{
                    fontSize: 30,
                    fontWeight: 'bold',
                    textDecorationLine: 'none',
                  }}
                >
                  LoMo
                </Text>
              </Link>
            </Group>
          </MediaQuery>
          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Link
              href="/"
              passHref
              onClick={(event) => {
                confetti({
                  particleCount: 100,
                  startVelocity: 10,
                  spread: 180,
                  angle: 300,
                  origin: {
                    x: event.pageX / window.innerWidth,
                    y: event.pageY / window.innerHeight,
                  },
                });
                // router.push('/');
              }}
              style={{
                textDecorationLine: 'none',
                userSelect: 'none',
                cursor: 'pointer',
              }}
            >
              <Text
                inherit
                variant="gradient"
                gradient={{ from: '#0037FC', to: '#F60063', deg: 135 }}
                sx={{
                  fontSize: 24,
                  fontWeight: 'bold',
                  textDecorationLine: 'none',
                }}
              >
                LoMo
              </Text>
            </Link>
          </MediaQuery>

          {/* <div style={{ flex: 1 }} className={classes.hiddenMobile} /> */}
          <Group
            maw={1000}
            sx={{ height: '100%' }}
            style={{ flex: '1 !important' }}
            spacing={0}
            className={classes.hiddenMobile}
          >
            <Autocomplete
              value={tierSearch}
              onClick={(e) => {}}
              itemComponent={forwardRef<HTMLDivElement, ItemProps>(
                ({ id, value, description, thumbnail, slug, ...others }, ref) => (
                  <div ref={ref} {...others}>
                    <Group
                      noWrap
                      onMouseDown={() => {
                        router.push(`/${id}/${slug}`);
                      }}
                    >
                      <Image
                        src={`${TIER_IMAGES_CDN}/${thumbnail}`}
                        width={60}
                        height={60}
                        withPlaceholder
                      />
                      <div>
                        <Text size="sm" fw="bold" lineClamp={1}>
                          {value}
                        </Text>
                        <Text size="xs" color="dimmed" lineClamp={1}>
                          {description}
                        </Text>
                      </div>
                    </Group>
                  </div>
                )
              )}
              onChange={setTierSearch}
              sx={{ width: '100%' }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  router.push(`/search?q=${encodeURIComponent(tierSearch)}`);
                }
              }}
              className={classes.search}
              placeholder="Search for Tier Lists"
              icon={<IconSearch size={20} stroke={1.5} />}
              data={autoCompleteData}
            />
          </Group>
          {/* <div style={{ flex: 1 }} className={classes.hiddenMobile} /> */}

          <Group className={classes.hiddenMobile} position="right">
            {!isLoading && (
              <>
                {!hasPremium && !premiumPastDue && session != null && premiumLoaded && (
                  <Link href="/upgrade" passHref>
                    <Button
                      variant="gradient"
                      gradient={{ from: 'violet', to: 'pink' }}
                      onClick={() => {
                        confetti({
                          particleCount: 100,
                          startVelocity: 30,
                          spread: 360,
                          origin: {
                            x: Math.random(),
                            // since they fall down, start a bit higher than random
                            y: Math.random() - 0.2,
                          },
                        });
                      }}
                    >
                      Upgrade to LomoPlus!
                    </Button>
                  </Link>
                )}

                <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                <Link href="https://link.lomolist.com/web-header" passHref target="_blank">
                  <Button
                    color="pink.6"
                    onClick={() => {
                      confetti({
                        particleCount: 100,
                        startVelocity: 30,
                        spread: 360,
                        origin: {
                          x: Math.random(),
                          // since they fall down, start a bit higher than random
                          y: Math.random() - 0.2,
                        },
                      });
                    }}
                  >
                    Get the app
                  </Button>
                </Link>
                </MediaQuery>
                <Link href="/create" passHref>
                  <Button
                    color="violet"
                    onClick={() => {
                      confetti({
                        particleCount: 100,
                        startVelocity: 30,
                        spread: 360,
                        origin: {
                          x: Math.random(),
                          // since they fall down, start a bit higher than random
                          y: Math.random() - 0.2,
                        },
                      });
                    }}
                  >
                    Create a Template
                  </Button>
                </Link>
                {premiumPastDue && (
                  <Link
                    href="https://billing.stripe.com/p/login/eVa3ePfSB2BX03C8ww"
                    passHref
                    target="_blank"
                  >
                    <Button color="red">Subscription Failed</Button>
                  </Link>
                )}
                {session ? (
                  <>
                    <Menu
                      width={260}
                      position="bottom-end"
                      transitionProps={{ transition: 'pop-top-right' }}
                      onClose={() => setUserMenuOpened(false)}
                      onOpen={() => setUserMenuOpened(true)}
                      withinPortal
                    >
                      <Menu.Target>
                        <UnstyledButton
                          className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                        >
                          <Group spacing={7}>
                            <Avatar src="" alt={session.user.email} radius="sm" size={35} />
                            <Text> {username} </Text>
                            {hasPremium && (
                              <Tooltip label="LomoPlus Subscriber">
                                <IconStar size={rem(12)} stroke={1.5} color="gold" />
                              </Tooltip>
                            )}
                            <IconChevronDown size={rem(12)} stroke={1.5} />
                          </Group>
                        </UnstyledButton>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() => {
                            router.push(`/profile/${username}`);
                          }}
                          icon={
                            <IconListNumbers
                              size="0.9rem"
                              color={theme.colors.green[2]}
                              stroke={1.5}
                            />
                          }
                        >
                          Your tier lists
                        </Menu.Item>
                        {!hasPremium && (
                          <Menu.Item
                            onClick={() => {
                              router.push('/upgrade');
                            }}
                            color="gold"
                            icon={
                              <IconStar size="0.9rem" color={theme.colors.orange[3]} stroke={2.5} />
                            }
                          >
                            Upgrade to LomoPlus
                          </Menu.Item>
                        )}
                        <Menu.Item
                          onClick={() => {
                            const end = Date.now() + 5 * 1000;

                            // go Buckeyes!
                            const colors = [theme.colors.violet[5], theme.colors.pink[5]];

                            (function frame() {
                              confetti({
                                particleCount: 2,
                                angle: 60,
                                spread: 55,
                                origin: { x: 0 },
                                colors,
                              })?.catch(() => {
                                console.log('Caught confetti erro');
                              });
                              confetti({
                                particleCount: 2,
                                angle: 120,
                                spread: 55,
                                origin: { x: 1 },
                                colors,
                              })?.catch(() => {
                                console.log('Caught confetti erro');
                              });

                              if (Date.now() < end) {
                                requestAnimationFrame(frame);
                              }
                            })();
                          }}
                          icon={
                            <IconConfetti
                              size="0.9rem"
                              color={theme.colors.violet[5]}
                              stroke={1.5}
                            />
                          }
                        >
                          Super secret button
                        </Menu.Item>

                        <Menu.Divider />
                        <Menu.Label>Settings</Menu.Label>
                        <Menu.Item
                          onClick={() => {
                            router.push('/settings');
                          }}
                          icon={<IconSettings size="0.9rem" stroke={1.5} />}
                        >
                          Account settings
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            supabase.auth.signOut();
                          }}
                          color="red"
                          icon={<IconLogout size="0.9rem" stroke={1.5} />}
                        >
                          Logout
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </>
                ) : (
                  <>
                    <Button
                      variant="default"
                      onClick={() => {
                        signupHandlers.close();
                        setLoginOpen(true);
                      }}
                    >
                      Log in
                    </Button>
                    <Button
                      onClick={() => {
                        signupHandlers.open();
                        setLoginOpen(true);
                      }}
                    >
                      Sign up
                    </Button>
                  </>
                )}
                <ColorSchemeToggle />
              </>
            )}
          </Group>

          <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
            <Group
              position="right"
              style={{
                flex: 1,
              }}
              w="100%"
            >
              <ActionIcon onClick={() => setSearchOpen(true)}>
                <IconSearch />
              </ActionIcon>
              <Link href="https://link.lomolist.com/web-header" passHref target="_blank">
                  <Button
                    color="pink.6"
                    onClick={() => {
                      confetti({
                        particleCount: 100,
                        startVelocity: 30,
                        spread: 360,
                        origin: {
                          x: Math.random(),
                          // since they fall down, start a bit higher than random
                          y: Math.random() - 0.2,
                        },
                      });
                    }}
                  >
                    Get the app
                  </Button>
                </Link>
              {/* {!session ? (
                <Button
                  variant="filled"
                  onClick={() => {
                    signupHandlers.close();
                    setLoginOpen(true);
                  }}
                >
                  Log in
                </Button>
              ) : (
                <Link href="/create" passHref>
                  <Button
                    color="violet"
                    onClick={() => {
                      confetti({
                        particleCount: 100,
                        startVelocity: 30,
                        spread: 360,
                        origin: {
                          x: Math.random(),
                          // since they fall down, start a bit higher than random
                          y: Math.random() - 0.2,
                        },
                      });
                    }}
                  >
                    Create
                  </Button>
                </Link>
              )} */}
            </Group>
          </MediaQuery>
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title={
          <Text
            inherit
            variant="gradient"
            gradient={{ from: '#0037FC', to: '#F60063', deg: 135 }}
            sx={{
              fontSize: 24,
              fontWeight: 'bold',
              textDecorationLine: 'none',
            }}
          >
            LoMo
          </Text>
        }
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <Divider mb="xs" />
        <Stack sx={{ gap: 5 }}>
          {session && (
            <Link href={`/profile/${username}`} className={classes.link} onClick={closeDrawer}>
              <IconListNumbers color={theme.colors.green[2]} stroke={1.5} />
              <Text ml="xs">Your Tier Lists</Text>
            </Link>
          )}
          <Link href="/upgrade" passHref className={classes.link} onClick={closeDrawer}>
            <Button
              variant="gradient"
              size="sm"
              my={0}
              gradient={{ from: 'violet', to: 'pink' }}
              onClick={() => {
                confetti({
                  particleCount: 100,
                  startVelocity: 30,
                  spread: 360,
                  origin: {
                    x: Math.random(),
                    // since they fall down, start a bit higher than random
                    y: Math.random() - 0.2,
                  },
                });
              }}
            >
              Upgrade to LomoPlus!
            </Button>
          </Link>
          <UnstyledButton
            className={classes.link}
            sx={{
              display: 'flex',
            }}
            onClick={() => {
              closeDrawer();
              const end = Date.now() + 5 * 1000;

              const colors = [theme.colors.violet[5], theme.colors.pink[5]];

              (function frame() {
                confetti({
                  particleCount: 2,
                  angle: 60,
                  spread: 55,
                  origin: { x: 0 },
                  colors,
                })?.catch(() => {
                  console.log('Caught confetti erro');
                });
                confetti({
                  particleCount: 2,
                  angle: 120,
                  spread: 55,
                  origin: { x: 1 },
                  colors,
                })?.catch(() => {
                  console.log('Caught confetti erro');
                });

                if (Date.now() < end) {
                  requestAnimationFrame(frame);
                }
              })();
            }}
          >
            <IconConfetti color={theme.colors.violet[5]} stroke={1.5} />
            <Text ml="xs">Super secret button </Text>
          </UnstyledButton>
          {session && (
            <Link href="/settings" className={classes.link} onClick={closeDrawer}>
              <IconSettings color="violet" stroke={1.5} />
              <Text ml="xs">Account Settings </Text>
            </Link>
          )}
          <Group
            position="left"
            sx={{ gap: 10 }}
            className={classes.link}
            onClick={() => toggleColorScheme()}
          >
            {theme.colorScheme === 'dark' ? (
              <IconSun size={20} color="gold" stroke={1.5} />
            ) : (
              <IconMoonStars color="blue" size={20} stroke={2} />
            )}
            <Text>{theme.colorScheme === 'dark' ? 'Light Mode' : 'Dark Mode'}</Text>
          </Group>
          <Divider my="sm" />
          {!session && (
            <Group position="center" grow pb="xl" px="md">
              <Button
                variant="default"
                onClick={() => {
                  closeDrawer();
                  signupHandlers.close();
                  setLoginOpen(true);
                }}
              >
                Log in
              </Button>
              <Button
                onClick={() => {
                  closeDrawer();
                  signupHandlers.open();
                  setLoginOpen(true);
                }}
              >
                Sign up
              </Button>
            </Group>
          )}
          {session && (
            <>
              <Text> Logged in as: {username}</Text>
              <Button
                onClick={() => {
                  supabase.auth.signOut();
                }}
              >
                Sign Out
              </Button>
            </>
          )}
        </Stack>
      </Drawer>
    </Box>
  );
});

export default HeaderMegaMenu;
