import { useRouter } from 'next/router';
import React from 'react';
import { StatsigProvider } from 'statsig-react';
import { useLog } from '../LogEventContext';
import * as LomoTime from '../LomoTimeTracker';

export function StatsigWrapper({
  children,
  userID,
  cookieID,
}: {
  children: React.ReactNode;
  userID?: string;
  cookieID: string;
}) {
  const { LogEvent, generateLogContext } = useLog();
  const router = useRouter();

  // You can use LogEvent here
  const initCallback = () => {
    LomoTime.setLogEvent(generateLogContext());
    LogEvent('page_view', window.location.pathname, {
      router_path: router.pathname,
      window_url: window.location.href,
    });
    LomoTime.initialize({
      currentPageName: router.asPath,
      idleTimeoutInSeconds: 30,
    });
    router.events.on('beforeHistoryChange', (event: string) => {
      LomoTime.sendCurrentTime();
      LomoTime.resetRecordedPageTime(router.asPath);
    });
    router.events.on('routeChangeComplete', (event: string) => {
      LogEvent('page_view', window.location.pathname, {
        router_path: event,
        window_url: window.location.href,
      });
      LomoTime.initialize({
        currentPageName: event,
        idleTimeoutInSeconds: 30,
      });
    });
  };

  return (
    <StatsigProvider
      sdkKey={process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY!}
      waitForInitialization={false}
      options={{
        environment: { tier: process.env.NODE_ENV },
        initCompletionCallback: initCallback,
      }}
      user={{ userID, customIDs: { cookieID } }}
    >
      {children}
    </StatsigProvider>
  );
}
