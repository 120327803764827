import {
  Button,
  createStyles,
  Group,
  Modal,
  rem,
  Space,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core';
import { useSession } from '@supabase/auth-helpers-react';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { EDGE_FUNCTIONS_URL, UID_COOKIE } from '../../src/Constants';
import { getDeviceID, invoke } from '../../src/Utils';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
  },

  inner: {
    position: 'relative',
    paddingTop: rem(200),
    paddingBottom: rem(120),

    [theme.fn.smallerThan('sm')]: {
      paddingBottom: rem(80),
      paddingTop: rem(80),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontSize: rem(62),
    fontWeight: 900,
    lineHeight: 1.1,
    margin: 0,
    padding: 0,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(42),
      lineHeight: 1.2,
    },
  },

  description: {
    marginTop: theme.spacing.xl,
    fontSize: rem(24),

    [theme.fn.smallerThan('sm')]: {
      fontSize: rem(18),
    },
  },

  controls: {
    marginTop: `calc(${theme.spacing.xl} * 2)`,

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xl,
    },
  },

  control: {
    height: rem(54),
    paddingLeft: rem(38),
    paddingRight: rem(38),

    [theme.fn.smallerThan('sm')]: {
      height: rem(54),
      paddingLeft: rem(18),
      paddingRight: rem(18),
      flex: 1,
    },
  },

  planGroup: {
    height: rem(70),
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.dark[2]
    }`,
    borderRadius: 7,
    padding: rem(10),
    marginTop: rem(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],

    '&:hover': {
      background: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
    },
  },

  planGroupSelected: {
    border: `2px solid ${theme.colors.violet[3]} !important`,
  },
}));

export default function StripeUpgradeModal({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}) {
  const { classes } = useStyles();
  const [annual, setAnnual] = useState(true);
  const [upgradeLoading, setUpgradeLoading] = useState(false);
  const session = useSession();
  const onUpgrade = async () => {
    setUpgradeLoading(true);
    const token = session?.access_token;
    if (token) {
      const { data } = await invoke('create-checkout', EDGE_FUNCTIONS_URL, token, {
        body: {
          plan: annual ? 'annual' : 'monthly',
          cookieID: getDeviceID(),
        },
      });
      const { url } = data;
      if (isMobile) {
        window.location.assign(url);
      } else {
        window.open(url, '_blank');
      }
    }
    setUpgradeLoading(false);
  };

  return (
    <>
      <Modal
        title={
          <Text fw={900} variant="gradient" gradient={{ from: 'violet', to: 'pink' }}>
            Upgrade to LomoPlus
          </Text>
        }
        centered
        opened={opened}
        onClose={onClose}
      >
        <Text fw={900} ta="center">
          This feature is only available for LomoPlus subscribers. Choose a plan to continue.
        </Text>

        <Stack sx={{ gap: 0 }} mt="md">
          <UnstyledButton
            className={`${annual && classes.planGroupSelected} ${classes.planGroup}`}
            onClick={() => {
              setAnnual(true);
            }}
          >
            <Text mr="md"> Annual</Text>
            <Group bg="violet" py="xxs" px="xs" sx={{ borderRadius: 4 }}>
              <Text size="sm" color="white">
                Save 57%
              </Text>
            </Group>
            <Group ml="xs" bg="green" py="xxs" px="xs" sx={{ borderRadius: 4 }}>
              <Text size="sm" color="white">
                Free Trial
              </Text>
            </Group>
            <Space sx={{ flex: 1 }} />
            <Text> $14.99</Text>
          </UnstyledButton>
          <UnstyledButton
            className={`${!annual && classes.planGroupSelected} ${classes.planGroup}`}
            onClick={() => {
              setAnnual(false);
            }}
          >
            <Text mr="md"> Monthly</Text>
            <Space sx={{ flex: 1 }} />
            <Text> $2.99</Text>
          </UnstyledButton>

          <Button my="md" size="lg" onClick={onUpgrade} loading={upgradeLoading}>
            {' '}
            {annual ? 'Try free for 7 days' : 'Upgrade now'}
          </Button>
          <Text component="a" href="tos" target="_blank" size="xs" color="dimmed" td="underline">
            {' '}
            Terms and Conditions
          </Text>
        </Stack>
      </Modal>
    </>
  );
}
