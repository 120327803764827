import {
  createStyles,
  Text,
  Container,
  ActionIcon,
  Group,
  rem,
  Button,
  Footer,
  Stack,
} from '@mantine/core';
import { IconBrandTwitter, IconBrandYoutube, IconBrandInstagram } from '@tabler/icons-react';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { WEBSITE_DOMAIN } from '../../src/Constants';
import { useStore } from '../../stores/stores';

const useStyles = createStyles((theme) => ({
  footerOuter: {
    height: 275,
    paddingTop: rem(30),

    [theme.fn.smallerThan('sm')]: {
      height: 275,
      paddingTop: rem(10),
      paddingBottom: rem(10),
    },
  },

  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',

    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan('sm')]: {
      paddingTop: rem(10),
      paddingBottom: rem(10),
    },
  },

  logo: {
    maxWidth: rem(200),

    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  description: {
    marginTop: rem(5),

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      textAlign: 'center',
    },
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  groups: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  wrapper: {
    width: rem(160),
  },

  link: {
    display: 'block',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    paddingTop: rem(3),
    paddingBottom: rem(3),

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

interface FooterLinksProps {
  data: {
    title: string;
    links: { label: string; link: string }[];
  }[];
}

const FooterLinks = observer(() => {
  const { classes } = useStyles();
  const { hasPremium } = useStore(null);
  const data = [
    {
      title: 'Create',
      links: [{ label: 'New Template', link: `/create` }, { label: 'Download app', link: `https://link.lomolist.com/website-footer` }],
    },
  ];
  if (!hasPremium) {
    data.push({
      title: 'Upgrade',
      links: [{ label: 'Upgrade to LomoPlus', link: `/upgrade` }],
    });
  }
  data.push({
    title: 'Support',
    links: [
      { label: 'Leave Feedback', link: 'https://twitter.com/SethSetse' },
      { label: 'Twitter', link: 'https://twitter.com/SethSetse' },
      { label: 'Instagram', link: 'https://instagram.com/seth_setse' },
    ],
  });
  data.push({
    title: 'Legal',
    links: [
      { label: 'Prviacy Policy', link: '/privacy' },
      { label: 'Terms of Service', link: '/tos' },
    ],
  });
  const groups = data.map((group) => {
    const links = group.links.map((link, index) => (
      <Link
        key={index}
        href={link.link}
        passHref
        style={{ textDecorationLine: 'none' }}
        target={group.title === 'Social' ? '_blank' : '_self'}
      >
        <Text className={classes.link}>{link.label}</Text>
      </Link>
    ));

    return (
      <div className={classes.wrapper} key={group.title}>
        <Text className={classes.title}>{group.title}</Text>
        {links}
      </div>
    );
  });

  return (
    <>
      <Container className={classes.footerOuter} />
      <Stack className={`${classes.footerOuter} ${classes.footer}`}>
        <Container className={classes.inner}>
          <div className={classes.logo}>
            <Text
              inherit
              variant="gradient"
              gradient={{ from: 'violet', to: 'pink' }}
              sx={{
                fontSize: 30,
                fontWeight: 'bold',
                textDecorationLine: 'none',
              }}
            >
              LoMo
            </Text>
            <Text size="xs" color="dimmed" className={classes.description}>
              The best Tier List platform on the internet. Build and share Tier Lists with ease.
            </Text>
          </div>
          <div className={classes.groups}>{groups}</div>
        </Container>
        <Container className={classes.afterFooter}>
          <Text color="dimmed" size="sm">
            © 2023 LomoList.com. All rights reserved.
          </Text>

          <Group spacing={0} className={classes.social} position="right" noWrap>
            <Button
              variant="subtle"
              color="dark"
              size="xs"
              onClick={() => {
                window.open('https://www.youtube.com/watch?v=dQw4w9WgXcQ');
              }}
            >
              {' '}
              Do not Click
            </Button>
          </Group>
        </Container>
      </Stack>
    </>
  );
});

export default FooterLinks;
