import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';
import { isMobile } from 'react-device-detect';
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { SupabaseClient } from '@supabase/supabase-js';
import Smartlook from 'smartlook-client'
import pack from './package.json';
import { useStore } from './stores/stores';

interface LogType {
  LogEvent: (name: string, value?: string | number, metadata?: any) => void;
  generateLogContext: () => void;
}

interface LogEventProviderProps {
  children: ReactNode;
  cookieID: string;
  supabaseClient: SupabaseClient;
}

interface CustomNavigator extends Navigator {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userAgentData?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mediaSession: any;
}

//define log interface
interface LogEvent {
  name: string;
  value?: string | number;
  user_metadata: {};
  metadata: {};
  client_timestamp: number;
  cookieID: number;
}
// Define the context

const noop = () => {
  throw new Error('Make sure that you have wrapped your webpage with LogContext');
};

const LogContext = createContext<LogType>({
  LogEvent: noop,
  generateLogContext: noop,
});

function _getOSVersion(): string {
  let OSName = '';
  if (navigator.userAgent.indexOf('Windows NT 10.0') !== -1) OSName = 'Windows 10';
  if (navigator.userAgent.indexOf('Windows NT 6.3') !== -1) OSName = 'Windows 8.1';
  if (navigator.userAgent.indexOf('Windows NT 6.2') !== -1) OSName = 'Windows 8';
  if (navigator.userAgent.indexOf('Windows NT 6.1') !== -1) OSName = 'Windows 7';
  if (navigator.userAgent.indexOf('Windows NT 6.0') !== -1) OSName = 'Windows Vista';
  if (navigator.userAgent.indexOf('Windows NT 5.1') !== -1) OSName = 'Windows XP';
  if (navigator.userAgent.indexOf('Windows NT 5.0') !== -1) OSName = 'Windows 2000';
  if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Mac/iOS';
  if (navigator.userAgent.indexOf('X11') !== -1) OSName = 'UNIX';
  if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux';

  return OSName;
}

function _browserVersion(): string {
  let browserVersion = '';
  if (navigator.userAgent.includes('Firefox/')) {
    browserVersion = `Firefox v${navigator.userAgent.split('Firefox/')[1]}`;
  } else if (navigator.userAgent.includes('Edg/')) {
    browserVersion = `Edg v${navigator.userAgent.split('Edg/')[1]}`;
  } else if (navigator.userAgent.includes('Chrome/')) {
    browserVersion = `Chrome v${navigator.userAgent.split('Chrome/')[1]}`;
  }

  return browserVersion;
}

export const LogProvider = ({ children, cookieID, supabaseClient }: LogEventProviderProps) => {
  const {
    setLogEvent,
    createStore: { setCreateLogEvent },
  } = useStore(null);

  const _deviceEvent = () => {
    const manufacturer = (window.navigator as CustomNavigator).userAgentData?.platform;
    const deviceInfo = {
      version: _browserVersion(),
      type: isMobile ? 'mobile' : 'browser',
      manufacturer,
      model: '',
      os_name: _getOSVersion(),
      os_version: '',
      install_id: cookieID,
    };

    return deviceInfo;
  };

  const generateLogContext = () => {
    const deviceID = _deviceEvent();
    const appInfo = {
      name: 'Lomo Website',
      version: pack.version,
    };

    const body = {
      app: appInfo,
      cookieID,
      client_timestamp: Date.now(),
      device: deviceID,
      environment: process.env.NODE_ENV,
    };

    return body;
  };

  const LogEvent = (name: string, value?: string | number, metadata?: any) => {
    const deviceID = _deviceEvent();
    const appInfo = {
      name: 'Lomo Website',
      version: pack.version,
    };
    const body = {
      app: appInfo,
      cookieID,
      client_timestamp: Date.now(),
      device: deviceID,
      metadata: {
        ...metadata,
        sessionUrl: Smartlook.initialized() ? Smartlook.playUrl : undefined
      },
      value,
      name,
      environment: process.env.NODE_ENV,
    };

    supabaseClient.functions
      .invoke('log-event', {
        body,
      })
      .then(({ data, error }) => {
        if (error) {
          console.error('Error logging is', error);
          console.error('sent data', body);
        }
      });
  };

  useEffect(() => {
    setLogEvent(LogEvent);
    setCreateLogEvent(LogEvent);
  }, [LogEvent]);

  return (
    <LogContext.Provider value={{ LogEvent, generateLogContext }}>{children}</LogContext.Provider>
  );
};

// Define a hook to use the log context
export const useLog = () => useContext(LogContext);
